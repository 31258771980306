import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const borrowerMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE),
    textKey: 'pageTitles.customerCentre',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
    textKey: 'pageTitles.nextJobProgram',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CONTACT_US),
    textKey: 'pageTitles.contactUs',
  },
];

export default borrowerMenu;
